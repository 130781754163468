import { render, staticRenderFns } from "./image.block.pug?vue&type=template&id=201bf207&scoped=true&lang=pug&external"
import script from "./image.block.ts?vue&type=script&lang=ts&external"
export * from "./image.block.ts?vue&type=script&lang=ts&external"
import style0 from "./image.block.sass?vue&type=style&index=0&id=201bf207&prod&lang=sass&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "201bf207",
  null
  
)

export default component.exports