import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import get from "lodash/get";

@Component({
	name: "ImageBlock",
})
export default class ImageBlock extends Vue {
	@Prop() private wordpress!: boolean;
	@Prop() private source!: any;
	@Prop() private caption!: string;
	@Prop() private alt!: string;
	@Prop() private color!: string;
	@Prop() private lazy!: boolean;

	private size: string = "";
	private url: string = "";
	private load: boolean = true;

	private created() {
		console.log("=== ImageBlock Created ===");
		console.log("Source:", this.source);
		console.log("WordPress:", this.wordpress);

		window.addEventListener("resize", () => {
			if (this.wordpress) {
				this.evalSize();
			}
		});
	}

	private mounted() {
		console.log("=== ImageBlock Mounted ===");
		console.log("WordPress flag:", this.wordpress);
		console.log("Source:", this.source);

		// Always evaluate size if we have sizes
		if (this.source && this.source.sizes) {
			this.lazyLoad();
			this.evalSize();
		} else if (typeof this.source === "string") {
			this.url = this.source;
		} else if (this.source && this.source.url) {
			this.url = this.source.url;
		}
	}

	private evalSize() {
		console.log("=== Evaluating Size ===");
		const elementWidth = this.$el.parentElement!.offsetWidth;
		const elementHeight = this.$el.parentElement!.offsetHeight;

		const sizes = {
			huge: 1440,
			great: 1024,
			large: 768,
			medium: 320,
		};

		const widthHeightRatio = 3;

		let size = this.size;

		if (elementWidth > sizes.huge || elementHeight > sizes.huge / widthHeightRatio) {
			size = "huge";
		} else if (
			this.size !== "huge" &&
			(elementWidth > sizes.great || elementHeight > sizes.great / widthHeightRatio)
		) {
			size = "great";
		} else if (
			this.size !== "great" &&
			this.size !== "huge" &&
			(elementWidth > sizes.large || elementHeight > sizes.large / widthHeightRatio)
		) {
			size = "large";
		} else if (
			this.size !== "large" &&
			this.size !== "great" &&
			this.size !== "huge" &&
			(elementWidth > sizes.medium || elementHeight > sizes.medium / widthHeightRatio)
		) {
			size = "medium";
		} else if (
			this.size !== "medium" &&
			this.size !== "large" &&
			this.size !== "great" &&
			this.size !== "huge" &&
			elementWidth > 0
		) {
			size = "small";
		}

		this.size = size;
		console.log("Selected size:", size);

		// Get URL from sizes
		if (this.source && this.source.sizes) {
			// Try to get the selected size or fall back to original
			this.url = this.source.sizes[size] || this.source.sizes.original || this.source.sizes.large || "";
			console.log("Set URL to:", this.url);
		}
	}

	private lazyLoad() {
		if ("IntersectionObserver" in window) {
			const imageEl = this.$el;

			const options = {
				root: null,
				rootMargin: "0px",
				threshold: 0,
			};

			const handleIntersection = (entries: any) => {
				entries.forEach((entry: any) => {
					if (entry.intersectionRatio > 0) {
						this.load = true;
					}
				});
			};

			const observer = new IntersectionObserver(handleIntersection, options);
			observer.observe(imageEl);
		} else {
			this.load = true;
		}
	}
}
