import { render, staticRenderFns } from "./discs.page.pug?vue&type=template&id=7001e426&scoped=true&lang=pug&external"
import script from "./discs.page.ts?vue&type=script&lang=js&external"
export * from "./discs.page.ts?vue&type=script&lang=js&external"
import style0 from "./discs.page.sass?vue&type=style&index=0&id=7001e426&prod&lang=sass&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7001e426",
  null
  
)

export default component.exports